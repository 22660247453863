<template>
  <div class="container" v-title :data-title="$t('i18n.ipcWarning')">
    <div id="outer-title">{{ $t("i18n.ipcWarning") }}</div>
    <div v-loading="loading">
      <jl-table :tableData="tableData" @init="init" :options="options" :columns="columns" :operates="operates"
        :total="total" :height="$defined.HEIGHT - 360 + 'px'">
      </jl-table>
    </div>
    <el-dialog :title="$t('i18n.sendOrder')" v-model="dialogFormVisible" width="50%" :before-close="handleClose"
      destroy-on-close>
      <jl-form :columns="addColumns" :option="btnOption"></jl-form>
    </el-dialog>
    <el-dialog v-if="dialogVisible2" :title="直播" v-model="dialogVisible2" width="840px" height="560px"
      :before-close="handleClose" destroy-on-close>
      <div class="root">
        <VideoPlay :othersId="othersId" :accessToken="accessToken" />
      </div>
      <template #footer>
        <el-button @click="dialogVisible2 = false">
          {{ $t("i18n.cancel") }}
        </el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import moment from 'moment'
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";
import jlTable from "../../components/table";
import jlForm from "../../components/form";
import VideoPlay from "./VideoPlay.vue"
export default {
  name: "AlarmMessage",
  components: {
    jlTable,
    jlForm,
    VideoPlay
  },

  setup(props, { emit }) {
    const router = useRouter();
    const route = useRoute();
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    const state = reactive({
      dialogVisible2: false,
      othersId: null,
      accessToken: null,
      dialogFormVisible: false,
      imgSrc: "",
      formNumber: {

      },
      formInline: {
        pageNumber: 1,
        pageSize: 10,
      },
      // table数据
      tableData: [],
      options: {
        // table 的参数
        isAdd: false, // 是否启用新增功能
        isEdit: false, // 是否启用编辑功能
        isDelete: false, // 是否启用删除功能
        highlightCurrentRow: false, // 是否支持当前行高亮显示
        multiSelect: false, // 是否支持列表项选中功能
        pageShow: true, // 是否翻页
      },
      btnOption: [
        {
          label: "sendLater",
          class: "primary",
          show: proxy.$defined.btnPermission("派发工单"),
          method: (row, validate) => {
            submitForm(row, validate, 2);
          },
        },
        {
          label: "sendImmediately",
          class: "reset",
          show: proxy.$defined.btnPermission("派发工单"),
          method: (row, validate) => {
            submitForm(row, validate, 1);
          },
        },
        {
          label: "cancel",
          class: "cancel",
          show: true,
          method: () => {
            dialogOnEvent("dialogFormVisible", false);
          },
        },
      ],
      addColumns: [
        // {
        //   prop: "billModel",
        //   label: "orderType",
        //   type: "select",
        //   data: [],
        //   uploadUrl: "billModel",
        //   props: { label: "code", value: "value" },
        //   rules: [{ required: true, message: t("i18n.select") }],
        //   // change: (val) => {
        //   //   setOrderType(val);
        //   // },
        // },
        {
          prop: "billModel",
          label: "orderType",
          value: '困人工单',
          isUpdate: true,
          readonly: true,
        },
        {
          prop: "enumber",
          label: "elevatorNo",
          type: "remote",
          props: { label: "number", value: null },
          rules: [{ required: true, message: t("i18n.input") }],
          loading: false,
          data: [],
          change: (val) => {
            getAllPersons(val, "addColumns", "maintenancePersonnelId");
          },
          remoteMethod: (val) => {
            remoteEleMethod(val, "addColumns", "enumber");
          },
        },
        {
          prop: "maintenancePersonnelId",
          label: "maintenancePerson",
          type: "select",
          data: [],
          props: { label: "name", value: "id" },
          rules: [{ required: true, message: t("i18n.select") }],
        },
        {
          prop: "faultPerson",
          label: "serviceStaff",
          type: "input",
          show: true,
        },
        {
          prop: "faultPersonTelephone",
          label: "contractInformation",
          type: "input",
          show: true,
        },
        {
          prop: "faultDescription",
          label: "faultDescription",
          type: "input",
          show: true,
        },
      ],
      columns: [
        // 需要展示的列
        {
          prop: "number",
          label: "elevatorNo",
          align: "center",
        },
        {
          prop: "billNumber",
          label: "billNumber",
          align: "center",
        },
        {
          prop: "warningType",
          label: "warningType",
          align: "center",
        },
        // {
        //   prop: "levels",
        //   label: "faultLevel",
        //   align: "center",
        //   formatter: (row) => {
        //     return row.levels ? t("i18n." + row.levels) : "-";
        //   },
        // },
        // {
        //   prop: "serviceMode",
        //   label: "elevatorStatus",
        //   align: "center",
        // },
      ],
      operates: {
        // 列操作按钮
        width: 220,
        fixed: "right",
        list: [

          {
            id: "1",
            label: "sendOrder",
            icon: "el-icon-view",
            show: true,
            plain: false,
            disabled: false,
            type: "inner",
            method: (index, row) => {
              console.log(row);
              state.formNumber = row
              state.dialogFormVisible = true;
            },
          },
          {
            id: "2",
            label: "ignore",
            icon: "el-icon-delete",
            show: true,
            plain: false,
            disabled: false,
            type: "inner",
            method: (index, row) => {
              let params = {
                status: '1',
                billNumber: row.billNumber
              }
              proxy.$api.maintenance.ipcWarning(params, row.id);
              proxy.$defined.tip("忽略成功", 'success');
            },
          },
          {
            id: "3",
            label: "playback",
            show: true,
            plain: false,
            disabled: false,
            type: "inner",
            method: async (index, row) => {
              console.log(row);
              let { data } = await proxy.$api.system.getElevatorDetail(row.deviceId);
              if (data.othersId) {
                let begin = moment(row.warningTime).format('YYYYMMDDHHmmss')
                let end = moment(moment(row.warningTime).add(15, 'minutes').format('YYYY-MM-DDTHH:mm:ss')).format('YYYYMMDDHHmmss');
                console.log(begin, end);
                state.othersId = `${data.othersId.split('1.')[0]}1.rec?begin=${begin}&end=${end}`
              }
              if (data.glf) {
                let item = await proxy.$api.common.getGlfAccToken();
                state.accessToken = item.data.data.accessToken
              } else {
                let item = await proxy.$api.common.getAccToken();
                state.accessToken = item.data.data.accessToken
              }
              state.dialogVisible2 = true
            },
          },

        ],
      },
      total: 0,
      loading: true,
    });
    const dialogOnEvent = (dialog, bool) => {
      state[dialog] = bool;
    };
    const submitForm = (form, validate, actionType) => {
      console.log(form);
      form.billModel = '40'
      // 派发工单
      validate
        .then(() => {
          submitOnEvent(form, actionType);
        })
        .catch(() => { });
    };
    const submitOnEvent = async (data, actionType) => {
      let form = JSON.parse(JSON.stringify(data));
      let obj = {};

      obj.elevatorId = form.elevatorId = form.enumber.id;
      obj.enumber = form.enumber = form.enumber.number; {
        if (route.query.enumber) {
          form.elevatorId = route.query.id;
          form.enumber = route.query.enumber;
          form.billModel = route.query.billModel || form.billModel;
        }
        form.alarm = state.formNumber.warningId
        form.actionType = actionType;
        let addBillOneList = await proxy.$api.maintenance.addBillOne(form);
        console.log(addBillOneList);
        let params = {
          status: '3',
          billNumber: addBillOneList.data[0]
        }
        proxy.$defined.tip(t("i18n.new") + t("i18n.success"), "success");
        await proxy.$api.maintenance.ipcWarning(params, state.formNumber.id);
        state.dialogFormVisible = false;

      }
      init(state.formInline);
    };
    const remoteEleMethod = (val, column, key) => {
      var index = state[column].findIndex((item) => {
        return item.prop === key;
      });
      if (val) {
        state[column][index].loading = true;
        setTimeout(() => {
          proxy.$api.maintenance
            .getAllEle({ elevatorNumber: val })
            .then((data) => {
              state[column][index].data = data.data;
              state[column][index].loading = false;
            });
        }, 10);
      } else {
        state[column][index].data = [];
      }
    };
    const getAllPersons = async (val, column, key) => {
      // 电梯工号 change
      let obj = { elevatorId: val.id, name: "" };
      let { data } = await proxy.$api.maintenance.getBillPerson(obj);
      var index = state[column].findIndex((item) => {
        return item.prop === key;
      });
      state[column][index].data = data;
    };
    const init = async (params) => {
      state.loading = true;
      params.eleNumber = params.number;
      const { data } = await proxy.$api.rescue.getIpcWarningList(params);
      state.tableData = data.records;
      state.total = data.total;
      state.loading = false;

    };

    const elevatorFaultConfirm = (id) => {
      let callback = () => {
        proxy.$api.rescue.errorsDelete(id).then(() => {
          init(state.formInline);
          proxy.$defined.tip(t("i18n.ignore") + t("i18n.success"), "success");
        });
      };
      proxy.$defined.confirm(
        callback,
        t("i18n.doYouWantToIgnore"),
        t("i18n.ignore")
      );
    };

    return {
      ...toRefs(state),
      elevatorFaultConfirm,
      getAllPersons,
      remoteEleMethod,
      init,
    };
  },
};
</script>
